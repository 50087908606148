import React from 'react';
import { SubPageTheme } from '../components/SubPageTheme';
import { Text } from '../components/Text';
import { SubPageServiceTheme } from './SubPageServiceTheme';
import { ListItem } from '../components/ListItem';
import { Service } from './PageServices';

const textes = [
    'reprezentuję Klientów i podejmuję przedsądowe działania polubowne,',
    'sporządzam pisma procesowe, wnioski, pozwy, apelacje i inne środki zaskarżenia w postępowaniach cywilnych, procesowych i nieprocesowych,',
    'reprezentuję Klientów w postępowaniach przed sądami powszechnymi wszystkich instancji i przed Sądem Najwyższym.',
];

export const PageServiceRepresentation = () => {
    return (
        <SubPageTheme headerBg="/resources/images/TOP_zastepstwo_procesowe_polubowne_rozstrzyganie_sporow.jpg">
            <SubPageServiceTheme
                header="Zastępstwo procesowe i polubowne rozstrzyganie sporów"
                activeService={Service.Representation}
            >
                <Text>W ramach zastępstwa procesowego, na podstawie udzielonych pełnomocnictw procesowych:</Text>

                {textes.map((text: string, key: number) => (
                    <ListItem key={key.toString()} color="secondary" size="l" weight="700">
                        {text}
                    </ListItem>
                ))}
            </SubPageServiceTheme>
        </SubPageTheme>
    );
};
