import React from 'react';
import { SubPageServiceTheme } from './SubPageServiceTheme';
import { Text } from '../components/Text';
import { SubPageTheme } from '../components/SubPageTheme';
import { Service } from './PageServices';

export const PageServiceAdvices = () => {
    return (
        <SubPageTheme headerBg="/resources/images/TOP_udzielanie_porad_prawnych.jpg">
            <SubPageServiceTheme header="Udzielanie porad prawnych" activeService={Service.Advices}>
                <Text>
                    To usługa mająca na celu wyjaśnienie problematycznych kwestii związanych ze stosowaniem określonego
                    rodzaju przepisów. Jej celem jest rozwiązanie sprawy, z którą przychodzi dana osoba. Porada prawna
                    bardzo często jest wielowątkowa i zahacza o różne dziedziny prawa. Klient co prawda przychodzi do
                    kancelarii zazwyczaj tylko z jednym pytaniem, góra dwoma, a po wstępnej rozmowie okazuje się, że ma
                    dodatkowe wątpliwości, które należy wyjaśnić.
                </Text>
            </SubPageServiceTheme>
        </SubPageTheme>
    );
};
